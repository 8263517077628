import { Application } from 'stimulus'
import { Controller } from 'stimulus'

export default class NotificacionesController extends Controller {
  static targets = ['contadorNoLeidas', 'content', 'verMas']

  marcarLeidaClick (e) {
    const url = e.target.dataset.url

    fetch(url)
      .then(response => response.text())
      .then(responseText => {
        this.contadorNoLeidasTarget.innerText = '(' + responseText + ')'

        e.target.parentElement.classList.remove('rnk-HoverableList_ItemWrapper-unread')
        e.target.parentElement.classList.add('rnk-HoverableList_ItemWrapper')
        e.target.remove()
      })
  }

  marcarTodasLeidasClick (e) {
    const url = e.target.dataset.url

    fetch(url)
      .then(_ => {
        this.contadorNoLeidasTarget.innerText = '(0)'

        this.element.querySelectorAll('.rnk-HoverableList_ItemWrapper-unread').forEach(function (elem) {
          elem.classList.remove('rnk-HoverableList_ItemWrapper-unread')
          elem.classList.add('rnk-HoverableList_ItemWrapper')
          elem.querySelector('.rnk-HoverableList_ItemMarkRead').remove()
        })
      })
  }

  verMasClick () {
    const url = this.buildVerMasURL(this.siguientePagina())

    fetch(url)
      .then(response => response.text())
      .then(responseText => {
        this.verMasTarget.remove()
        this.contentTarget.insertAdjacentHTML('beforeend', responseText)
      })
  }

  //
  // Private
  //

  buildVerMasURL (pagina) {
    const url = this.baseNotificacionesURL()

    url.pathname += '/ver-mas/'
    url.search = `?page=${pagina}`

    return url
  }

  baseNotificacionesURL () {
    const url = new URL(location)

    url.pathname = 'notificaciones'

    return url
  }

  siguientePagina () {
    this.data.set('pagina', parseInt(this.data.get('pagina')) + 1)

    return this.data.get('pagina')
  }
}

const application = Application.start()
application.register('notificaciones', NotificacionesController)
